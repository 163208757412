import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
/**
 * TODO move this in local settings
 */
const useDarkLightThemeStore = defineStore('darkLightThemeStore', () => {
    /**
     * Selected theme in settings, can be "light" or "dark", but also "auto".
     * See displayedTheme to get current theme used.
     */
    const selectedTheme = ref('auto');
    const displayedTheme = () => {
        if ('auto' !== selectedTheme.value) {
            return selectedTheme.value;
        }
        return getSystemPreferredTheme();
    };
    const getSystemPreferredTheme = () => window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
    const switchTheme = () => {
        selectedTheme.value = displayedTheme() === 'light'
            ? 'dark'
            : 'light';
    };
    const storedTheme = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('selectedTheme');
    if (storedTheme === 'light' || storedTheme === 'dark') {
        selectedTheme.value = storedTheme;
    }
    document.documentElement.setAttribute('data-bs-theme', displayedTheme());
    watch(selectedTheme, theme => {
        localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem('selectedTheme', theme);
        document.documentElement.setAttribute('data-bs-theme', displayedTheme());
    });
    return {
        selectedTheme,
        displayedTheme,
        switchTheme,
    };
});
export default useDarkLightThemeStore;
